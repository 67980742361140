<template>
  <div id="newsDetail">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="newsDetailCon">
      <!--内容头部-->
      <div class="commenHead">
        <div class="about_tit">
          <p class="animated bounceInLeft">快速掌握<br/>
            前沿研究与新知
          </p>
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>

      <!--      动态详细内容-->
      <div class="detailBox" >
        <div class="detailCon">
          <div v-show="hasData">
            <div class="topTitle">
              <span class="theMonthDay">{{dateInfo.theMonth}}月{{dateInfo.theDay}}日</span>
              <span class="theYear">{{dateInfo.theYear}}</span>
            </div>
            <div style="padding-top: 13.888vw;border-bottom: 0.2777vw solid #20BFD0;margin-bottom: 5.555vw;"></div>
            <div class="title">{{detailCon.title}}</div>
            <div class="text" v-html="detailCon.content" style="font-size: 3.333vw;line-height: 5.8333vw;text-align: justify"></div>
          </div>
          <div style="color:#000;opacity: 0.8" v-show="!hasData">
            <div style="padding-top: 13.888vw;border-bottom: 0.2777vw solid #20BFD0;margin-bottom: 5.555vw;"></div>暂无数据</div>

        </div>

      </div>
<!--      上一页下一页按钮-->
      <div class="btnBox">
        <v-touch @press="press(nextInfo.id,'l')" @pressup="pressup(nextInfo.id,'l')" @tap="tap(nextInfo.id,'l')">
          <div class="arr_btn arr_l" :class="isLeftActive?'arr_l_a':''">
            <p v-show="nextInfo.id!=0">
              <span></span>上一篇
            </p>
            <p v-show="nextInfo.id==0">没有了</p>
          </div>

        </v-touch>
        <v-touch @press="press(prevInfo.id,'r')" @pressup="pressup(prevInfo.id,'r')" @tap="tap(prevInfo.id,'r')">
          <div class="arr_btn arr_r" :class="isRightActive?'arr_r_a':''">
            <p v-show="prevInfo.id!=0">
              下一篇<span></span>
            </p>
            <p v-show="prevInfo.id==0">没有了</p>
          </div>

        </v-touch>

      </div>
      <div style="margin-top: 16.666vw;" v-show="hasData">
        <Copyright></Copyright>
      </div>
    </div>
    <div style="width: 100%;position: fixed;bottom: 0; left: 50%;transform: translateX(-50%)" v-show="!hasData">
      <Copyright></Copyright>
    </div>
  </div>
</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import {GetNewsDetail} from "@/api/api";
export default {
  data(){
    return{
      //是否返回数据
      hasData:true,
      //当前列表
      detailCon: {},
      dateInfo: {},
      nextInfo:{},
      prevInfo:{},
      //上一页是否激活
      isLeftActive:false,
      //下一页是否激活
      isRightActive:false,
    }
  },
  mounted() {
    const id=this.$route.query.id
    this.toGetNewsDetail(id)
  },
  methods:{
    toGetNewsDetail(id){
      let _this=this
      GetNewsDetail(id)
          .then(r=>{
            let res=r.data

            if(res.status!=1){
              _this.hasData=false
              return
            }
            _this.hasData=true
            _this.detailCon=res.data
            _this.nextInfo=res.data.next
            _this.prevInfo=res.data.prev
            _this.dateInfo.theMonth=res.data.date.slice(5,7)
            _this.dateInfo.theDay=res.data.date.slice(8,10)
            _this.dateInfo.theYear=res.data.date.slice(0,4)
          })
    },
    //点击按住
    press(e,i){
      if(i=='l'){//左侧
        this.isLeftActive=true
      }else if(i=='r'){//右侧
        this.isRightActive=true
      }
    },
    //按住后抬起
    pressup(e,i){
      if(i=='l'){//左侧阅读文章按钮
        this.isLeftActive=false
      }else if(i=='r'){//右侧
        this.isRightActive=false
      }
      if(e==0){
        return
      }else{
        this.$router.push({path:'/index/doc/newsDocDetail',query:{id:e}})
      }
    },
    // 点击
    tap(e,i){
      let _this=this
      if(i=='l'){
        _this.isLeftActive=true
        setTimeout(function (){
          _this.isLeftActive=false
          if(e==0){
            return
          }else{
            _this.$router.push({path:'/index/doc/newsDocDetail',query:{id:e}})
          }
        },200)
      }else if(i=='r'){
        _this.isRightActive=true
        setTimeout(function (){
          _this.isRightActive=false
          if(e==0){
            return
          }else{
            _this.$router.push({path:'/index/doc/newsDocDetail',query:{id:e}})
          }
        },200)
      }
      }
  },
  components:{
    baseNav,
    Copyright
  }
}
</script>
<style lang="less" scoped>
#newsDetail{
  padding-top: 13.888vw;
  background-color: #071D42;
  min-height: calc(100vh - 13.888vw);
}
.newsDetailCon{
  .commenHead{
    background:url("../assets/images/pic_banner_zhengxun.png") no-repeat 77% top;
    background-size: 156%;
  }
  //循证内容
  .detailBox{
    margin:0 6.666vw;

    .detailCon{
      font-size:3.333vw;
      margin-top:-5.555vw;
      padding: 0 4.444vw 5.555vw;
      background: #fff;
      border-radius: 1.1111vw;
      position: relative;
      .topTitle{
        padding:0 1.666vw;
        position: absolute;
        top: -1.388vw;
        height: 10vw;
        border-bottom-right-radius: 3.333vw;
        background: #20BFD0;
        span{
          line-height: 10vw;
          display: inline-block;
          font-size:3.888vw;
          color:#FAFBFD;
        }
        .theMonthDay{
          font-weight: 600;
        }
        .theYear{
          margin-left: 0.5555vw;
          font-weight: 400;
        }
      }
      .title{
        font-size: 4.444vw;
        line-height: 6.3888vw;
        color: #2B2B2B;
        font-weight:600;
        margin-bottom: 3vw;
      }
    }
  }
  //按钮盒子
  .btnBox{
    margin: 4.444vw 6.666vw 0;
    display: flex;
    justify-content: space-between;
    .arr_btn{
      height: 11.111vw;
      width: 41.111vw;
      border:1px solid #20BFD0;
      border-radius: 2px;
      padding:0 3.333vw;
      box-sizing: border-box;
      line-height: calc(11.111vw - 1px);
      color:#20BFD0;
      font-size:3.333vw;
      p{
        width: 100%;
        height: 100%;
      }
      span{
        display: inline-block;
        width: 3.62vw;
        height: calc(11.111vw - 1px);
        vertical-align: bottom;
        background:url("../assets/images/ico_arrow_now.png") center center no-repeat;
        background-size: 100%;

      }
    }
    .arr_l{
      span{
        margin-right: 2.222vw;
      }
    }
    .arr_l_a{
      color:#071D42;
      background:#20BFD0;
      span{
        background:url("../assets/images/ico_arrow_active.png") center center no-repeat;
        background-size: 100%;
        transform: rotate(180deg);

      }
    }
    .arr_r{
      text-align: right;
      span{
        margin-left: 2.222vw;
        background:url("../assets/images/ico_arrow_now.png") center center no-repeat;
        transform: rotate(180deg);
        background-size: 100%;
      }
    }
    .arr_r_a{
      color:#071D42;
      background:#20BFD0;
      span{
        background:url("../assets/images/ico_arrow_active.png") center center no-repeat;
        background-size: 100%;
        transform: rotate(0deg);
      }
    }

  }

}
</style>
<style>
.newsDetailCon .detailBox .detailCon .text img{
  width: calc(100vw - 22.222vw)!important;
  height: auto!important;
}
.newsDetailCon .detailBox .detailCon video{
  width:100%!important;
  height: auto!important;
}
</style>